.contentWrapper {
    position: relative;
    padding: 0px 5px;
    max-width: 550px;
    margin: auto;
  }
  
  .CallNowButton {
    margin: 0 auto;
    font-weight: bold;
    color: #ffffff;
    background-color: #ec780b;
    border-radius: 25px;
    box-shadow: 1 1 6px -1px rgba(0,0,0,0.7);
    height: 60px;
    width: calc(260px + (320 - 260) * ((100vw - 300px) / (1600 - 300)));
    font-size: calc(18px + (22 - 16) * ((100vw - 300px) / (1600 - 300)));
    white-space: nowrap;
    font-weight: bold;
}
.CallNowButton:hover {
    background-color: #0ddf3bad;
}
.CallNowButton { outline: none; }


.ha-button {
    margin: 0 auto;
    font-weight: bold;
    color: #ffffff;
    background-color: #01bcf3;
    border-radius: 20px;
    box-shadow: 1 1 6px -1px rgba(0,0,0,0.7);
    height: 60px;
    width: calc(260px + (320 - 260) * ((100vw - 300px) / (1600 - 300)));
    font-size: calc(16px + (22 - 16) * ((100vw - 300px) / (1600 - 300)));
    white-space: nowrap;
    font-weight: bold;
}
.ha-button:hover {
    background-color: #043df7;
}
.ha-button:focus { outline: none; }

.hiddenItem { 
    display: none;
}

.ha-button-continue {
    margin: 0 auto;
    font-weight: bold;
    color: #ffffff;
    background-color: #01bcf3;
    border-radius: 20px;
    box-shadow: 1 1 6px -1px rgba(0,0,0,0.7);
    height: 60px;
    width: calc(250px + (320 - 235) * ((100vw - 300px) / (1600 - 300)));
    font-size: calc(16px + (22 - 16) * ((100vw - 300px) / (1600 - 300)));
    white-space: nowrap;
    font-weight: bold;
}
.ha-button-continue:hover {
    background-color: #043df7;
}
.ha-button-continue:focus { outline: none; }


.ha-button-continue2 {
    margin: 0 auto;
    font-weight: bold;
    color: #ffffff;
    background-color: #01bcf3;
    border-radius: 20px;
    box-shadow: 1 1 6px -1px rgba(0,0,0,0.7);
    height: 60px;
    width: calc(290px + (320 - 235) * ((100vw - 300px) / (1600 - 300)));
    font-size: calc(12px + (22 - 16) * ((100vw - 300px) / (1600 - 300)));
    white-space: nowrap;
    font-weight: bold;
}
.ha-button-continue2:hover {
    background-color: #043df7;
}
.ha-button-continue2:focus { outline: none; }


.hiddenItem { 
    display: none;
}

.bTitle {
    text-transform:capitalize;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
}

.tTitle {
    text-transform:capitalize;
    font-size: 12px;
    font-weight: bold;
    color: #1d5882;
}


.questionTitle {
    font-size: 125%;
    line-height: 40px;
    vertical-align: bottom;
}

.hiddenText {
    display:none;
}

.buttonText { 
    text-align: 'center';
    line-height: 53px;
}
/* doesnt work, React respects this only when inlined
.buttonTextPointer{
  color: #ff0000;
  position: 'relative';
  top: '0';
  right: '0';
}*/

.thumbnail {
    width:100px;
    padding:1px;
    border:1px solid #ff0000;
}