/* Footer */

.Footer-App-logo {
	width: 25vmin;
	pointer-events: none;
  }

.d-footer-first {
	background-color: white;
	text-align: center;
	padding-top: 1px;
	font-size: 26px;
	width: 100%;
	overflow: hidden;
}
.d-footer-first a {
	color: #0e4d98;
	font-size: 22px;

}
.d-footer-first-links, .d-footer-first-links a {
	color: #535355;
	font-size: 10px;
	text-decoration: none;
	font-weight: 400;
}
.d-footer-first li, .d-footer-first ul li a {
	display: inline-block;
	text-align: center;
	padding: 3px;
}
/*.d-footer-first li a:after {
	content: '-';
	padding-left: 6px;
}*/


.d-footer-second, .d-footer-second a {
	text-align:center;
	background-color: #074e9f;
	color: white;
	/*min-height: 140px;*/
	font-size: 10px;
}
.d-footer-second p {
	text-align:center;
}


.d-footer-third {
	min-height: 50px;
	background-color: #535355;
	color: white;
	text-align: center;
}
.d-footer-third p {
	text-align: center;
	padding-top: 5px;
}
.copyright {
	font-size: 16px;
	text-align: center;
}