 .ha-offer-button-disabled {
    margin: 0 auto;
    font-weight: bold;
    color: #dadada;
    background-color: #a6a6a6;
    height: 65px;
    border-radius: 10px;
    box-shadow: 1 1 6px -1px rgba(0,0,0,0.7);
    width: calc(190px + (250 - 190) * ((100vw - 300px) / (1600 - 300)));
    font-size: calc(16px + (24 - 16) * ((100vw - 300px) / (1600 - 300)));
}

.ha-offer-button {
    margin: 0 auto;
    font-weight: bold;
    color: #ffffff;
    background-color: #01bcf3;
    height: 65px;
    border-radius: 10px;
    box-shadow: 1 1 6px -1px rgba(0,0,0,0.7);
    width: calc(190px + (250 - 190) * ((100vw - 300px) / (1600 - 300)));
    font-size: calc(16px + (24 - 16) * ((100vw - 300px) / (1600 - 300)));
}
.ha-offer-button:focus { outline: none; }
.ha-offer-button:hover {
    background-color: #043df7;
}

.ha-secondary-offer-button {
    margin: 0 auto;
    font-weight: bold;
    color: #ffffff;
    background-color: #ff9900;
    height: 50px;
    border-radius: 20px;
    box-shadow: 1 1 6px -1px rgba(0,0,0,0.7);
    width: calc(260px + (320 - 260) * ((100vw - 300px) / (1600 - 300)));
    font-size: calc(16px + (22 - 16) * ((100vw - 300px) / (1600 - 300)));
    line-height: 37px;
}
.ha-secondary-offer-button:focus { outline: none; }
.ha-secondary-offer-button:hover {
    background-color: red;
}



.ha-secondary-offer-credit-button {
    margin: 0 auto;
    font-weight: bold;
    color: #ffffff;
    background-color: #01bcf3;
    height: 70px;
    border-radius: 20px;
    box-shadow: 1 1 6px -1px rgba(0,0,0,0.7);
    width: calc(200px + (320 - 200) * ((100vw - 300px) / (1600 - 300)));
    font-size: calc(16px + (24 - 16) * ((100vw - 300px) / (1600 - 300)));
    line-height: 30px;
}
.ha-secondary-offer-credit-button:focus { outline: none; }
.ha-secondary-offer-credit-button:hover {
    background-color: #043df7;
}


.estimateHeader {
    font-size: 18px;
    font-weight: bold;
}
.dollarEstimate {
    color: green;
    font-weight: bold;
    font-size: 18px;
}

.tcpaText {
    font-size: 13px;
    line-height: 15px;
}

.textInputStyle {
    width: 245px;
    height: 60px;
    text-align:center;
    font-size: 26px;
    color: black;
}