/* Doesnt work for breaking tool tip with hard returns */
.tooltip-inner {
    white-space: pre-wrap;
}

.container {
    width:100%;
    vertical-align: middle;
}
.pNumbers {
    text-align: center;
}

.textInputStylePhone12 {
    width: 66px;
    height: 50px;
    text-align:center;
    font-size: 22px;
    color: black;
    border-radius: 10px;

}
.textInputStylePhone12::-webkit-outer-spin-button,
.textInputStylePhone12::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.textInputStylePhone12[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.textInputStylePhone3 {
    width: 82px;
    text-align:center;
    height: 50px;
    font-size: 22px;
    color: black;
    border-radius: 10px;
}
.textInputStylePhone3::-webkit-outer-spin-button,
.textInputStylePhone3::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; 
}
.textInputStylePhone3[type=number] {
    -moz-appearance:textfield;
}